<template>
  <div>
    <myHeader />
    <main id="content">
      <HeaderBrokerComponent />
      <section class="dashboard-section"
               v-if="listing">
        <div class="container">
          <h3 class="m-0">
            {{ listing.property_name }}
          </h3>
          <button class="btn btn-outline-primary mt-2"
                  @click="handleHREF(listing.property_id)">
            VIEW PROPERTY
          </button>
          <div class="row mt-4">
            <div class="col-12 full-calendar">
              <datepicker
                placeholder="Available Dates"
                v-model="date"
                :clear-button="false"
                :inline="true"
                :disabled-dates="disabledDates" />
            </div>
          </div>
          <h4 class="mt-4">
            Owner Reservation
          </h4>
          <div class="row mt-4"
               v-if="showBlockoutForm">
            <div class="col-12">
              <form>
                <label>Check In:</label>
                <div class="form-group">
                  <datepicker
                    placeholder="Select Date"
                    v-model="checkIn"
                    :clear-button="true"
                    :disabled-dates="disabledDates" />
                </div>
                <label>Check Out:</label>
                <div class="form-group">
                  <datepicker
                    placeholder="Select Date"
                    v-model="checkOut"
                    :clear-button="true"
                    :disabled-dates="disabledDates" />
                </div>
                <label>Reason:</label>
                <div class="form-group">
                  <textarea name="reason"
                            v-model="reason" />
                </div>
                <button type="button"
                        class="btn btn-outline-primary mt-2"
                        @click="requestBlockout">
                  Request Reservation
                </button>
              </form>
            </div>
          </div>
          <div class="row mt-4"
               v-if="!showBlockoutForm">
            <div class="col-12">
              <h5>{{ blockoutMessage }}</h5>
            </div>
          </div>
        </div>
      </section>
    </main>
    <myFooter />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import HeaderBrokerComponent from '../../components/broker/Header.vue';

export default {
  name: 'Listing',
  components: {
    HeaderBrokerComponent,
    Datepicker,
  },
  data() {
    return {
      listing: null,
      date: null,
      disabledDates: null,
      checkIn: null,
      checkOut: null,
      reason: null,
      showBlockoutForm: true,
      blockoutMessage: '',
    };
  },
  methods: {
    handleHREF(id) {
      const { href } = this.$router.resolve({
        name: 'property',
        params: { id },
        query: this.$route.query
      });
      window.open(href, '_blank');
    },
    requestBlockout() {
      const formData = new FormData();
      formData.append('property_id', JSON.stringify(this.listing.property_id));
      formData.append('check_in', JSON.stringify(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(this.checkIn)));
      formData.append('check_out', JSON.stringify(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(this.checkOut)));
      formData.append('reason', JSON.stringify(this.listing.reason));
      this.$axios.post('/v1/brokers/bookings', formData).then(() => {
        this.showBlockoutForm = false;
        this.blockoutMessage = 'The request has been received.';
      }).catch(error => Promise.reject(error));
    },
  },
  created() {
    this.$axios.get(`/v1/brokers/properties/${this.$route.params.id}`).then((response) => {
      this.listing = response.data;
      const dates = [];
      this.listing.property_disabled_dates.forEach((date) => {
        dates.push(new Date(date));
      });
      this.disabledDates = {
        dates
      };
    });
  },
};
</script>

<style>
  .dashboard-section {
    padding-top: 45px;
  }
  .accordion-table h6 {
    max-width: 140px;
  }
  .full-calendar .vdp-datepicker__calendar {
    width: 100% !important;
  }
  .vdp-datepicker__calendar .cell {
    margin: 10px 0;
  }
  .form-group textarea {
    height: 200px;
  }
</style>
