<template>
  <div>
    <main id="content"
          class="broker-content">
      <HeaderBrokerComponent />
      <section class="dashboard-section">
        <div class="container">
          <h3 class="m-0">
            Listings
          </h3>
          <div class="row">
            <div class="col-12">
              <div class="accordion-table">
                <!-- title of accordion -->
                <ul class="head-accordion listings-accordion">
                  <li>PROPERTY</li>
                  <li>LOCATION</li>
                  <li>TRAVELERS</li>
                  <li>BED</li>
                  <li>BATH</li>
                <!-- <li>PRICE/NIGHT</li> -->
                </ul>
                <vsa-list class="no-arrow listings-accordion">
                  <vsa-item v-for="(listing, index) in listings"
                            :key="index">
                    <vsa-heading>
                      <!-- property -->
                      <div class="item-information-accordion-li">
                        <div class="d-flex align-items-center">
                          <div class="thumb">
                            <img :src="listing.property_image.thumbnail">
                          </div>
                          <h6>
                            <a class="text-black text-decoration-none"
                               href="javascript:;"
                               @click="handleHREF(listing.property_id)">{{ listing.property_name }}<small /></a>
                          </h6>
                        </div>
                      </div>
                      <div class="item-information-accordion-li">
                        {{ listing.property_location }}
                      </div>
                      <!-- TRAVELERS -->
                      <div class="item-information-accordion-li">
                        {{ listing.property_travelers }}
                      </div>
                      <!-- BED -->
                      <div class="item-information-accordion-li">
                        {{ listing.property_bedrooms }}
                      </div>
                      <!-- BATH -->
                      <div class="item-information-accordion-li">
                        {{ listing.property_bathrooms }}
                      </div>
                      <!-- PRICE/NIGHT -->
                      <!-- <div class="item-information-accordion-li">
                      ${{ listing.property_price }}
                    </div> -->
                      <div class="item-information-accordion-li">
                        <i class="icon-cheveron-down" />
                      </div>
                    </vsa-heading>
                  <!-- <vsa-content>
                    <div class="cont">
                      <div class="content-description"
                           v-for="(commission, cIndex) in listing.commissions"
                           :key="cIndex">
                        <h6><small>{{ commission.source_name }}</small></h6>
                        <p>{{ commission.booking_commission }}%</p>
                      </div>
                    </div>
                  </vsa-content> -->
                  </vsa-item>
                </vsa-list>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <myFooter />
  </div>
</template>

<script>
import { VsaList, VsaItem, VsaHeading } from 'vue-simple-accordion';
import HeaderBrokerComponent from '../../components/broker/Header.vue';

export default {
  name: 'Listings',
  components: {
    HeaderBrokerComponent,
    VsaList,
    VsaItem,
    VsaHeading,
    // VsaContent,
  },
  data() {
    return {
      listings: null
    };
  },
  methods: {
    handleHREF(id) {
      const { href } = this.$router.resolve({
        name: 'broker-listing',
        params: { id },
        query: this.$route.query
      });
      window.open(href, '_blank');
    },
  },
  created() {
    this.$axios.get('/v1/brokers/properties').then((response) => {
      this.listings = response.data;
    });
  },
};
</script>

<style>
  .accordion-table h6 {
    max-width: 140px;
  }
</style>
