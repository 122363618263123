<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: {
    earnings: {
      type: Object,
      default: null
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: [...this.earnings.months.map(month => month.month)],
        datasets: [
          {
            label: 'Total Earnings',
            backgroundColor: '#585858',
            data: [...this.earnings.months.map(month => month.total_earnings)]
          },
          {
            label: 'Total Charges',
            backgroundColor: '#9B9B9B',
            data: [...this.earnings.months.map(month => month.total_charges)]
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false }
    );
  }
};
</script>
