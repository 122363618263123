<template>
  <div>
    <main id="content"
          class="broker-content"
          v-if="cEarnings && cEarnings.months && cEarnings.months.length > 0">
      <HeaderBrokerComponent />
      <section class="dashboard-section earnings-section">
        <div class="container">
          <h3 class="m-0">
            Earnings
          </h3>
          <div class="year-earnings">
            <div class="earnings">
              <div class="select-year">
                <div class="form-group has-icon m-0">
                  <i class="icon-calendar" />
                  <select v-model="year"
                          class="form-control"
                          @change="getEarnings"
                          name="year">
                    <option value="2021">
                      2021
                    </option>
                    <option value="2020">
                      2020
                    </option>
                  </select>
                  <i class="icon-cheveron-down right" />
                </div>
              </div>

              <div class="select-year">
                <div class="form-group has-icon m-0">
                  <i class="fas fa-building" />
                  <select v-model="property"
                          class="form-control"
                          @change="getEarnings"
                          name="property"
                          v-if="properties && properties.length > 0">
                    <option value="property">
                      Property
                    </option>
                    <option v-for="(p, index) in properties"
                            :value="p.property_id"
                            :key="index">
                      {{ p.property_name }}
                    </option>
                  </select>
                  <i class="icon-cheveron-down right" />
                </div>
              </div>

              <div class="earnings-description">
                <i class="icon-price" />
                <p>
                  ${{ (cEarnings.total_earnings - cEarnings.total_charges)| numFormat('0,0.00') }}
                  <small>Earnings</small>
                </p>
              </div>
              <div class="completed">
                <p class="text-black">
                  ${{ cEarnings.completed_earnings| numFormat('0,0.00') }}
                  <small>Completed</small>
                </p>
              </div>
              <div class="expected">
                <p class="expected-color">
                  ${{ cEarnings.expected_earnings| numFormat('0,0.00') }}
                  <small>Expected</small>
                </p>
              </div>
              <div class="completed">
                <p class="expected-color">
                  ${{ cEarnings.total_charges| numFormat('0,0.00') }}
                  <small>Charges</small>
                </p>
              </div>
            </div>
          </div>
          <Chart :earnings="cEarnings" />
          <div class="row m-0 mt-2 main-stats-paragraph">
            * Current values are estimated and could change throughout the month.
          </div>
          <hr>
          <h5 class="mb-3">
            Monthly report
          </h5>
          <div class="select-year">
            <div class="form-group has-icon m-0">
              <i class="icon-calendar" />
              <select class="form-control small"
                      v-model="selectedMonth"
                      @change="getMonthlyEarnings"
                      name="month">
                <option v-for="(m, index) in ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']"
                        :value="m"
                        :key="index">
                  {{ m }}
                </option>
              </select>
              <i class="icon-cheveron-down right" />
            </div>
          </div>
          <div v-if="monthlyEarnings"
               class="row">
            <div class="col-lg-7 col-md-8">
              <ul class="earning-list">
                <li>Booking Earnings <span>${{ monthlyEarnings.booking_total| numFormat('0,0.00') }}</span></li>
                <li v-if="monthlyEarnings.other_total"
                    class="text-gray">
                  Other earnings <span>${{ monthlyEarnings.other_total| numFormat('0,0.00') }}</span>
                </li>
                <li class="text-gray">
                  Charges <span>${{ monthlyEarnings.total_charges| numFormat('0,0.00') }}</span>
                </li>
                <li>Total <span class="text-green">${{ (monthlyEarnings.total_earnings - monthlyEarnings.total_charges)| numFormat('0,0.00') }}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
    <myFooter />
  </div>
</template>

<script>
// import moment from 'moment';
import HeaderBrokerComponent from '../../components/broker/Header.vue';
import Chart from '../../components/broker/Chart.vue';

export default {
  name: 'Earnings',
  components: {
    HeaderBrokerComponent,
    Chart
  },
  data() {
    return {
      earnings: null,
      monthlyEarnings: null,
      year: 2021,
      selectedMonth: this.getCurrentMonth(),
      property: 'property',
      properties: null,
    };
  },
  created() {
    this.getEarnings();
    this.$axios.get('/v1/brokers/properties').then((response) => {
      this.properties = response.data;
    });
  },
  computed: {
    cEarnings() {
      return this.earnings;
    }
  },
  methods: {
    getEarnings() {
      this.earnings = null;
      let queryString = `?date_from=${this.year}-01-01&date_to=${this.year}-12-31`;
      if (this.property !== 'property') {
        queryString += `&property_id=${this.property}`;
      }
      this.$axios.get(`/v1/brokers/reports${queryString}`).then((response) => {
        this.earnings = response.data;
        this.getMonthlyEarnings();
      });
    },
    getMonthlyEarnings() {
      const date = new Date(`1 ${this.selectedMonth} ${this.year}`);
      const firstDay = this.formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
      const lastDay = this.formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
      const queryString = `?date_from=${firstDay}&date_to=${lastDay}`;
      this.$axios.get(`/v1/brokers/reports${queryString}`).then((response) => {
        this.monthlyEarnings = response.data;
      });
    },
    formatDate(date) {
      const d = new Date(date);
      let month = `${d.getMonth() + 1}`;
      let day = `${d.getDate()}`;
      const year = d.getFullYear();
      if (month.length < 2) { month = `0${month}`; }
      if (day.length < 2) { day = `0${day}`; }
      return [year, month, day].join('-');
    },
    getCurrentMonth() {
      const d = new Date();
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return months[d.getMonth()];
    }
  },
};
</script>

<style>

</style>
